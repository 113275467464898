.topPart {
  margin: 0.5rem;
  background-color: #eb1701;
  grid-template-columns: 1.8fr 2.2fr 1.3fr;
  padding: 8px 0 0 0;

  @media (min-width: 618px) {
    padding: 0;
  }
}

.imageContainer {
  height: 82px;
}

.item {
  border: 1px solid #bebfbf;
  border-radius: 0;
}

.image {
  margin: 0;
  background-color: transparent;
  gap: 1rem;

  @media (min-width: 618px) {
    width: 100%;
    gap: 0;
  }
}

.logo {
  height: 80px;
  width: 80px;
  margin: 0;
  border: 1px solid #000000;
  border-radius: 0;

  @media (min-width: 618px) {
    margin-right: 0;
  }
}

.rating {
  padding: 0;
}

.ratingText {
  font-size: 16px;
  line-height: 1.2rem;
  text-transform: none;
  color: #fff;
  word-break: break-word;
}

.ratingStars {
  height: 22px;
  background-color: #fff;
  border-radius: 0;
  margin-left: 0;

  @media (min-width: 618px) {
    margin-left: 8px;
  }

  svg {
    margin-top: 8px;
  }
}

.info {
  padding: 1rem 0;
  pointer-events: auto;
  box-sizing: border-box;
  text-align: center;

  @media (min-width: 618px) {
    max-width: 269px;
    margin: 5px 0;
    padding: 0px 8px;
    text-align: left;
    max-height: 65px;
    overflow-y: scroll;
    box-sizing: border-box;

    /* width */
    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #191919;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.infoText {
  margin: 0;
  padding: 0 4px;
  display: inline;
  color: #fff;
  background-color: #000;
  text-align: left;
  font-size: 28px;
  text-transform: uppercase;
  line-height: 40px;

  @media (min-width: 618px) {
    line-height: 2rem;
    font-size: 21px;
  }
}

.cta {
  margin-bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f1f0;
  position: relative;
  padding-top: 40px;
  padding-bottom: 8px;

  &::after,
  &::before {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    height: 40%;
    position: absolute;
    top: 0;
  }

  &::before {
    background-image: linear-gradient(-171deg, #eb1701 50%, #f3f1f0 50%);

    left: 0;
  }

  &::after {
    background-image: linear-gradient(171deg, #eb1701 50%, #f3f1f0 50%);

    right: 0;
  }

  @media (min-width: 618px) {
    padding-top: 0;
    padding-bottom: 0;

    &::after {
      display: none;
    }

    &:before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      border: 41px solid #eb1701;
      border-bottom-color: #f3f1f0;
      border-right-color: #f3f1f0;
      border-top-color: #f3f1f0;
      border-left-width: 21px;
      width: 0;
      height: 100%;

      position: absolute;
      left: 0;
      z-index: 0;
    }
  }
}

.ctaButton {
  z-index: 1;
  width: 50%;
  border-radius: 4px;
  background-color: #0072ee;
  padding: 0 10px;
  height: 45px;
  white-space: nowrap;
  color: white;

  @media (min-width: 618px) {
    max-width: 105px;
    height: 40px;
  }
}

.tandc {
  background-color: #f4f4f4;
}

.tandcText {
  height: 53px;
  color: #000;

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #dcdddd;
  }
}
.item {
  border: 2px solid #000;
  border-radius: 0;
  margin-bottom: 16px;
}

.topPart {
  padding: 0;
  grid-template-columns: 1fr 1.5fr 1.2fr;
}

.tandc {
  display: none;
}

.rating {
  padding: 0;
}

.ratingText {
  color: white;
  font-size: 20px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: none;

  @media (min-width: 618px) {
    font-size: 16px;
  }
}

.info {
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 100%;
  background-color: #f0f0f0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 26px 0 16px 0;
  @media (min-width: 618px) {
    padding: 0;
  }

  &:before {
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    height: 10px;
    width: 100%;
    content: '';
    position: absolute;
    background-color: #f0f0f0;
    z-index: 0;
    bottom: 0;
    top: 100%;

    @media (min-width: 618px) {
      clip-path: polygon(100% 50%, 75% 0, 76% 100%);
      right: -24px;
      top: 0;
      height: 100%;
      width: 100px;
    }
  }

  > p:first-child {
    color: #082037;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;

    @media (min-width: 618px) {
      font-size: 16px;
    }
  }

  > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;

    @media (min-width: 618px) {
      font-size: 12px;
    }
  }
}

.ratingStars {
  background-color: transparent;
  color: #fff;
  margin-left: 0;
  @media (min-width: 618px) {
    margin-left: 8px;
  }
}

.number {
  position: absolute;
  color: white;
  height: 100%;
  top: 0;
  left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 17px;

  @media (min-width: 618px) {
    left: 0;
    font-size: 10px;
  }
}

.ratingStars {
  width: auto;
  > div {
    display: flex !important;

    > div {
      margin: 0;
      padding: 0 !important;

      > svg {
        margin-right: 2px !important;
      }

      > svg {
        margin-top: 9px;
        margin-right: 5px;
        height: 15px !important;
        width: 15px !important;
        
      }
    }
  }
}

.image {
  background-color: #000;
  position: relative;
  margin: 0;
  height: 100%;
  width: 100%;
  border-radius: 0;
  z-index: 2;

  > * {
    z-index: 1;
  }

  img {
    margin-left: 26px;
    width: 123px;
    height: 123px;
    border-radius: 0;

    @media (min-width: 618px) {
      width: 90px;
      height: 90px;
    }
  }

  &:before {
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    height: 10px;
    width: 100%;
    content: '';
    position: absolute;
    bottom: -9px;
    background-color: black;
    z-index: 0;

    @media (min-width: 618px) {
      clip-path: polygon(100% 50%, 75% 0, 76% 100%);
      height: 100%;
      width: 100px;
      top: 0;
      right: -24px;
    }
  }
}

.cta {
  margin-bottom: 24px;
  margin-top: 24px;

  @media (min-width: 618px) {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.ctaButton {
  border-radius: 3px;
  border: 2px solid #000;
  background: #2bec78;
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.2) inset;
  color: #000;
  position: relative;
  width: 80%;
  max-width: 70%;
  text-transform: uppercase;

  @media (min-width: 618px) {
    width: 70%;
  }

  &:after {
    content: '<';
    margin-left: 5px;
  }

  &:before {
    content: '>';
    margin-right: 5px;
  }
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('../../assets/fonts/Montserrat-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../../assets/fonts/Montserrat-Regular.woff2') format('woff2');
}


.container {
  font-family: 'Montserrat';
}

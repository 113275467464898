@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: url('../../assets/fonts/Roboto-Regular.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: url('../../assets/fonts/Roboto-Bold.woff2') format('woff2');
  }
  
  .container {
    font-family: 'Roboto';
    max-width: 900px;
  }
  
.item {
  border-radius: 6px;
  margin-bottom: 14px;
  border: none;
  background: #262626;
  position: relative;
  justify-content: center;

  @media (min-width: 618px) {
    margin-bottom: 8px;
  }

  &:before {
    content: ' ';
    position: absolute;
    height: 10px;
    width: 92%;
    border-top: 23px dotted #fff;
    left: 4%;
    top: -13px;
  }

  &:after {
    content: ' ';
    position: absolute;
    height: 10px;
    width: 92%;
    border-bottom: 23px dotted #fff;
    left: 4%;
    bottom: -13px;
  }

  @media (min-width: 618px) {
    height: 104px;
    border: 1px solid #e6e6e6;

    &::before,
    &::after {
      content: none;
    }
  }
}

.imageContainer {
  position: relative;

  @media (min-width: 618px) {
    height: 72px;
  }
}

.number {
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 0px 0px 3px 0px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  font-weight: 700;
  top: 0;
  border-radius: 6px 0px 3px 0px;
  left: 0;
  padding: 3px 5px;

  @media (min-width: 618px) {
    border-radius: 0px 0px 3px 0px;
  }
}

.topPart {
  background: #262626;
  grid-template-columns: 1fr 2fr 1.5fr;
  padding-top: 16px;

  @media (min-width: 618px) {
    border-radius: 6px;
    padding: 0;
    height: 100%;
  }
}

.logo {
  margin: 0;
  border-radius: 6px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  background-color: transparent;
  @media (min-width: 618px) {
    border-radius: 0px 6px 6px 0px;
  }
}

.tandc {
  display: none;
}

.image {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin-bottom: 26px;

  @media (min-width: 618px) {
    gap: 23px;
    margin: 0;
  }
}

.rating {
  padding: 12px;
  background: #38383d;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px 6px 6px 0px;

  @media (min-width: 618px) {
    border-radius: 0;
    height: calc(100% - 24px);
    width: 91px;
  }
}

.ratingText {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin: 0;
  font-weight: normal;
  text-transform: none;
}

.ratingStars {
  background-color: transparent;
  margin: 0;
  padding: 0;
  align-items: baseline;

  > span {
    color: #fff;
  }

  svg {
    display: none;
  }
}

.primaryRating {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.info {
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  background: #38383d;
  position: relative;
  height: 85%;
  width: 100%;
  max-width: 100%;
  padding: 19px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 618px) {
    width: 87%;
    margin-left: 34px;
    padding: 0;
  }

  > p:first-child {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1.8px;
    margin-bottom: 0;
    margin-top: 0;
    display: none;

    @media (min-width: 618px) {
      margin-top: 5px;
    }
  }

  > p:nth-child(2) {
    margin: 0;

    @media (min-width: 618px) {
      margin-top: 0;
    }
  }

  &:before &:after {
    content: ' ';
    width: 32px;
    height: 16px;
    border-radius: 12px;
    background: #262626;
    position: absolute;
    top: -8px;
    z-index: 1;
  }

  @media (min-width: 618px) {
    &:before {
      content: ' ';
      position: absolute;
      height: 100%;
      width: 10px;
      border-left: 10px dotted #262626;
      left: -5px;
    }

    &::after {
      display: none;
    }
  }
}

.cta {
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 0;
  background: #262626;
  padding-top: 10px;

  @media (min-width: 618px) {
    margin-right: 20px;
    padding-bottom: 0;
    padding-top: 0px;
    width: 90%;
    background-color: #38383d;

    // &:before {
    //   content: ' ';
    //   position: absolute;
    //   height: 100%;
    //   width: 10px;
    //   border-left: 2px dashed #262626;
    //   left: 0px;
    // }

    &:after {
      content: ' ';
      position: absolute;
      height: 100%;
      width: 10px;
      border-right: 10px dotted #262626;
      right: -5px;
    }
  }
}

.ctaButton {
  display: flex;
  height: auto;
  flex-direction: row-reverse;
  background-color: #f9dc1c;
  border-radius: 8px;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  gap: 24px;
  box-shadow: 0px 9px 32px 0px #000;
  padding: 6px 42px 6px 15px;
  border-bottom: 4px solid #b49f13;

  @media (min-width: 600px) {
    gap: 7px;
    padding: 6px 16px 6px 11px;
  }

  > img {
    width: 22px;
    height: 22px;

    @media (min-width: 600px) {
      width: 18px;
      height: 18px;
    }
  }
}

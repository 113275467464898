@font-face {
    font-family: 'Heebo';
    font-weight: 400;
    src: url('../../assets/fonts/Heebo-Regular.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Heebo';
    font-weight: 700;
    src: url('../../assets/fonts/Heebo-Bold.woff2') format('woff2');
  }

  .container {
    font-family: 'Heebo';
    max-width: 728px;
  }
  
.container {
  margin: 0 auto;
  max-width: 618px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced0dd;
  border-radius: 8px;
  font-family: 'Rambla';
  @media (min-width: 618px) {
    flex-direction: row;
  }
}

.topPart {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  @media (min-width: 618px) {
    flex-direction: row;
    width: auto;
    max-width: 73%;
  }
}

.image {
  margin: 16px auto;
  padding: 9px;
  width: calc((100% - 32px) * 0.8);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  background-color: #f1f2f9;
  @media (min-width: 618px) {
    margin: 16px;
    margin-right: 8px;
    width: auto;
    justify-content: flex-start;
  }
}

.logo {
  height: 72px;
  width: 72px;
  border: 1px solid #000;
  border-radius: 8px;
  background-color: #fff;
}

.rating {
  margin-left: 5px;
}

.ratingText {
  display: flex;
  color: #000;
}

.ratingStars {
  padding: 5px;
  width: fit-content;
  border-radius: 48px;
  text-align: center;
  color: #000;
  background-color: #ffe4cf;
}

.info {
  width: calc(100% - 32px);
  flex: 1 0 0;
  pointer-events: none;
  line-height: 27px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.infoText {
  margin: 0;
  padding: 3.2px;
  color: #000;
  @media (min-width: 618px) {
    text-align: left;
    padding: 3.2px 11px;
  }
}

.cta {
  margin: 8px 0 16px;
  width: auto;
  @media (min-width: 618px) {
    margin: 0 16px 0 auto;
    width: calc(28% - 26px);
  }
}

.ctaButton {
  padding: 12px 10px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  background-color: #ffdc4f;
}

.tandc {
  padding: 8px;
  width: calc(100% - 16px);
  background-color: #f1f2f9;
}

.tandcText {
  margin: 0;
  padding-right: 5px;
  height: 35px;
  overflow-y: scroll;
  line-height: 16px;
  font-size: 11px;
  font-weight: 400;
  color: #6e6e84;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #aaaaba;
  }
}

.item {
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 820px;
  margin: 0 auto;
}

.image {
  position: relative;
}

.topPart {
  position: relative;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 618px) {
    display: grid;
    grid-template-columns: 1fr 5fr;
  }
}

.logo {
  display: flex;
  gap: 16px;
  flex-grow: 1;
  > a {
    > img {
      width: 88px;
      height: 88px;
      border-radius: 8px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;
    span {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      color: #451a03;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #f59e0b;
      background: #fffbeb;
      width: fit-content;
      min-width: 60px;
      padding: 3px 16px;
      > span:last-of-type {
        font-size: 14px;
      }
    }

    @media (min-width: 618px) {
    }

    > img {
      margin-right: 4px;
    }

    > span {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
    }
    > span:last-child {
      font-size: 14px;
    }
  }
  order: 1;
}

.sellingPoint {
  padding: 0;
  width: 100%;
  list-style: none;

  @media (min-width: 618px) {
    width: 50%;
  }

  li {
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #0f172a;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2px;
  padding: 2px 8px;
  background: #dbeafe;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  color: #0f172a;
  @media (min-width: 618px) {
    display: none;
  }
}

.oneLiner {
  border-radius: 4px;
  border: 1px solid #E2E8F0;
  background: #F8FAFC;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #0f172a;
  font-weight: 600;
  line-height: 30px;
  order: 3;
  text-align: center;
  @media (min-width: 618px) {
    order: 2;
  }

  :first-child {
    font-size: 24px;
  }
  >p {
    margin: 0;
  }
}

.couponCode {
  border-radius: 4px;
  border: 1px dashed #64748b;
  background: #f8fafc;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  padding: 8px 16px;
  padding-right: 4.5rem;
  text-align: center;
  position: relative;
  color: #0f172a;
  grid-column: span 2;
  order: 2;
    @media (min-width: 618px) {
    order: 3;
  }

  button {
    border: none;
    cursor: pointer;
    gap: 4px;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    background: #f1f5f9;
    padding: 4px 8px;
    color: #0f172a;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bottomPart {
  display: flex;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  background: #22c55e;
  box-shadow: 0px 2px 8px 0px rgba(84, 149, 255, 0.3);
  gap: 8px;

  > div {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.2);
    width: auto;
    text-align: center;

    @media (min-width: 618px) {
      width: 50%;
    }

    p {
      font-size: 20px;
      color: #0f172a;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }

    p:last-of-type {
      font-size: 16px;
    }
  }
}

.ctaButton {
  padding: 16px 24px;
  display: flex;
  color: #eff6ff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  text-decoration: none;
  gap: 8px;
  justify-content: center;


  @media (min-width: 618px) {
  }
}


.topPart {
  
  padding: 0;
  display: flex;
  flex-direction: column;
  grid-template-columns: 1.5fr 2fr 1fr;
  place-items: center;
  background-color: #fff;
  @media (min-width: 618px) {
    display: grid;
    grid-gap: 0;
  }
}

.item {
  border: 1px solid #ced0dd;
  border-radius: 8px;
  margin-bottom: 24px;

  @media (min-width: 618px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
}

.image {
  margin: 16px auto 0;
  padding: 0;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  background-color: #f1f2f9;
  @media (min-width: 618px) {
    margin: 16px;
    width: calc(100% - 16px);
    justify-content: flex-start;
  }
}

.logo {
  margin: 8px;
  height: 72px;
  width: 72px;
  border: none;
  border-radius: 8px;
  background-color: #fff;
}

.rating {
  padding: 0 12px;
  @media (min-width: 618px) {
    padding: 0;
  }
}

.ratingText {
  display: flex;
  line-height: 24px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #17182f;
  @media (min-width: 618px) {
    margin-left: 8px;
    margin-bottom: 4px;
    max-width: 6rem;
    text-align: left;
  }
}

.ratingStars {
  padding-top: 2px;
  margin-left: 8px;
  height: 24px;
  width: 59px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-align: center;
  color: #17182f;
  background-color: #ffeecf;
  svg {
    margin-top: 9px;
    margin-right: 5px;
    height: 15px !important;
    width: 15px !important;
    // path {
    //   // fill: #f90;
    // }
  }
}

.info {
  max-width: 80%;
  pointer-events: none;
  line-height: 24px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  color: #17182f;
  @media (min-width: 618px) {
    max-width: fit-content;
  }
}

.infoText {
  padding: 0 18px;
}

.cta {
  margin-bottom: 20px;
  width: 100%;
  @media (min-width: 618px) {
    margin-bottom: 0;
  }
}

.ctaButton {
  margin: 0 auto;
  height: 40px;
  max-width: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  color: #000;
  background-color: #ffdc4f;
  @media (min-width: 618px) {
    margin-right: 19px;
    max-width: 115px;
  }
}

.tandc {
  padding: 8px;
  width: calc(100% - 16px);
  grid-column: 1/7;
  background-color: #f1f2f9;
}

.tandcText {
  margin: 0;
  padding-right: 5px;
  max-height: 53px;
  overflow-y: scroll;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #6e6e84;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #aaaaba;
  }
}

@font-face {
  font-family: 'Barlow';
  font-weight: 400;
  src: url('../../assets/fonts/Barlow-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 700;
  src: url('../../assets/fonts/Barlow-Bold.woff2') format('woff2');
}

.container {
  font-family: 'Barlow';
  max-width: 654px;
}

.topPart {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: 104px;
  counter-reset: toplist-counter;
  position: relative;
  @media (min-width: 610px) {
    width: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 13px;
  }
}

.item {
  border-radius: 4px;
  border: 2px solid #e4a400;
  padding: 0;
  counter-increment: toplist-counter;
  position: relative;

  &:before {
    position: absolute;
    content: counter(toplist-counter);
    background: #f2f2f2;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    border-bottom-right-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    color: #000;
    @media (min-width: 610px) {
      height: 100%;
      width: 17px;
      border-radius: 0;
    }
  }
}

.ratingText {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  justify-content: center;

  @media (min-width: 610px) {
    justify-content: flex-start;
  }
}

.image {
  background-color: transparent;
  margin: 0;
  flex-direction: column;
  grid-row: 1/3;
  @media (min-width: 610px) {
    grid-row: auto;
    width: auto;
    flex-direction: row;
  }

  > div:last-child {
    display: none;
    @media (min-width: 610px) {
      display: block;
    }
  }
}

.info {
  height: 100%;
  border-left: 3px solid #f9dd69;
    background-color: #fef8e3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px;
  max-width: 100%;
  height: 100%;

  @media (min-width: 610px) {
    font-size: 24px;
  }

  > p {
    margin: 0;
    padding: 0;
    @media (min-width: 610px) {
      margin: 0;
    }
  }

  &::after,
  &::before {
    content: none;
    display: block;
    position: absolute;
    right: -20px;
    top: 0;
    width: 0;
    height: 0;
    z-index: 1;
    border-style: solid;
    border-width: 54px 0 54px 20px;
    border-color: transparent transparent transparent #f9dd69;
    content: '';
  }

  &::before {
    right: -18px;
    z-index: 2;
    border-style: solid;
    border-width: 54px 0 54px 20px;
    border-color: transparent transparent transparent #fef8e3;
  }
}

.logo {
  height: 60px;
  width: 60px;
  border-radius: 0;
  margin: 0 8px;

  @media (min-width: 610px) {
    height: 90px;
    width: 90px;
  }
}

.ratingStars {
  width: auto;
  background-color: transparent;
  margin-left: 0;
  > div {
    display: flex !important;
    align-items: center;
    justify-content: center;

    > * {
      padding: 0 !important;
    }

    svg {
      margin: 0;
    }
  }
  // display: none;
  @media (min-width: 610px) {
    display: block;
  }
}

.cta {
  grid-column: 2;
  grid-row: 2;
  margin: 0;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 610px) {
    width: 100%;
    margin: 0 19px;
    grid-column: auto;
    grid-row: auto;
    height: 100%;
  }
}

.ctaButton {
  border-radius: 4px;
  border: 2px solid #00c752;
  background: #00ef63;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  text-transform: uppercase;
  height: 33px;
  width: 70%;
  margin-right: 5px;

  @media (min-width: 610px) {
    height: 40px;
    max-width: 115px;
    margin: 0 auto;
  }
}

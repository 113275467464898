@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: url('../../assets/fonts/Roboto-Regular.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: url('../../assets/fonts/Roboto-Bold.woff2') format('woff2');
  }

.container {
  flex-direction: column;
  border: 2px solid #000;
  border-radius: 0;
  font-family: 'Roboto';
}

.topPart {
  width: 100%;
  max-width: 100%;
  gap: 24px;
}

.image {
  background-color: black;
  margin: 0;
  padding: 22px;
  border-radius: 0;
  position: relative;
  width: calc((100% - 44px));
  @media (min-width: 618px) {
    width: auto;
  }


  &:before {
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    height: 10px;
    width: 100%;
    content: '';
    position: absolute;
    bottom: -9px;
    background-color: black;
    z-index: 0;

    @media (min-width: 618px) {
      clip-path: polygon(100% 50%, 75% 0, 76% 100%);
      height: 100%;
      width: 100px;
      top: 0;
      right: -24px;
    }
  }
}

.logo {
  height: 150px;
  border-radius: 0;
  width: 150px;
}

.info {
  > p {
    font-style: normal;
    text-transform: uppercase;
  }

  > p:nth-child(2) {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  > p:first-child {
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
  }
}


.cta {
    background: #F0F0F0;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-right: 0;
    width: 100%;


}

.ctaButton {
    width: auto;
    margin: 16px 24px;
    border-radius: 3px;
    border: 2px solid #000;
    background: #2bec78;
    box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.2) inset;
    color: #000;
    position: relative;
    text-transform: uppercase;

    &:after {
      content: '<';
      margin-left: 5px;
    }
  
    &:before {
      content: '>';
      margin-right: 5px;
    }
}

.tandc {
    background-color: white;
    border-top: 1px solid black;
    max-height: 48px;
    padding: 8px 24px;
    width: calc(100% - 48px);
  }

  .tandcText {
    color: #373737;
    font-size: 12px;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: #D6D6D6;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #fff;
    }
  }
.item {
  border: 1px solid #80808c;
  border-radius: 0;
  grid-template-columns: 2.11fr 2.5fr 1.67fr;
  grid-row-gap: 0;
}

.image {
  margin: 0 auto;
  padding: 10px;
  width: 85%;
  justify-content: flex-start;
  background-color: transparent;
  gap: 1rem;
  @media (min-width: 618px) {
    padding-right: 0;
    width: 100%;
    gap: 0;
  }
}

.logo {
  margin: 0;
  height: 96px;
  width: 96px;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.08);
  @media (min-width: 618px) {
    border: 1px solid #000;
  }
}

.rating {
  padding: 0;
  display: flex;
  align-items: center;
  @media (min-width: 618px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.ratingText {
  font-size: 14px;
  text-transform: none;
}

.ratingStars {
  border-radius: 0;
  background-color: #f2f2f2;
}

.info {
  margin: 8px auto;
  padding: 0 3px;
  min-height: 61px;
  max-width: 85%;
  width: 85%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  @media (min-width: 618px) {
    min-height: 104px;
    max-width: 80%;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    transform: skew(0deg, 3deg);
    background-color: #000;
    z-index: 1;
    @media (min-width: 618px) {
      top: auto;
      right: 0;
      bottom: 0;
      height: 50%;
      width: 100%;
      transform: skew(-15deg, 0deg);
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    transform: skew(0deg, -3deg);
    background-color: #000;
    z-index: 1;
    @media (min-width: 618px) {
      left: 0;
      height: 50.5%;
      width: 100%;
      transform: skew(15deg, 0deg);
    }
  }
}

.infoBG {
  margin: 0 auto;
  min-height: 61px;
  width: 100%;
  position: absolute;
  top: -5px;
  pointer-events: auto;
  @media (min-width: 618px) {
    top: 0;
    left: -4px;
    min-height: 98px;
    width: calc(100% + 10px);

  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: #fff200;
    transform: skew(0deg, 3deg);
    @media (min-width: 618px) {
      top: auto;
      right: 0;
      bottom: 0;
      height: 50%;
      width: 100%;
      transform: skew(-15deg, 0deg);
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: #fff200;
    transform: skew(0deg, -3deg);
    @media (min-width: 618px) {
      left: 0;
      height: 50.5%;
      width: 100%;
      transform: skew(15deg, 0deg);
    }
  }
}

.infoText {
  margin: 0;
  padding: 0 16px;
  z-index: 2;
}

.cta {
  margin-bottom: 4px;
  position: relative;
}

.ctaButton {
  width: 86%;
  max-width: unset;
  border-radius: 4px;
  color: #000;
  background-color: #fff200;
  @media (min-width: 618px) {
    margin-right: 28px;
    padding: 0 10px;
    width: fit-content;
    max-width: 128px;
  }
}

.ctaIcons {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 618px) {
    margin-top: 6px;
    position: absolute;
    right: 28px;
  }
}

.ctaIcon18 {
  margin-right: 11px;
  height: 14px;
  width: 14px;
  vertical-align: middle;
}

.ctaIconGA {
  height: 24px;
  vertical-align: middle;
}

.tandc {
  background-color: #f5f5f5;
}

.tandcText {
  height: 30px;
  line-height: 16px;
  color: #000;
}

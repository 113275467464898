@mixin rankNumber() {
  &::before {
    content: attr(data-rank);
    border-radius: 4px 0px 2px 0px;
    background: rgba(0, 0, 0, 0.3);
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    text-align: center;
    font-family: Barlow;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.topPart {
  border-radius: 8px;
  border: 1px solid #3b8456;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  padding: 12px 16px;

  @media (min-width: 618px) {
    grid-template-columns: 1.2fr 312px 1fr;
  }
}

.tandc {
  display: none;
}

.item {
  border: none;
  position: relative;
  margin: 8px 0;

  @media (min-width: 618px) {
  }
}

.imageContainer {
  width: 72px;
  height: 72px;
  position: relative;
  @include rankNumber();
  img {
    width: 72px;
    height: 72px;
    left: 10px;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

    @media (min-width: 618px) {
      width: 72px;
      height: 72px;
    }
  }
}

.image {
  margin: 0;
  position: relative;
  background-color: transparent;
  height: 72px;
  margin: 0 0 12px 0;
  gap: 16px;
  width: 100%;

  @media (min-width: 618px) {
    margin: 0;
    background-color: transparent;
    gap: 8px;
    &::before {
      left: 0;
      top: 1px;
    }
  }
}

.rating {
  padding: 12px 18px;
  margin-right: 8pxpx;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.ratingText {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
  text-align: center;
  text-transform: capitalize;

  @media (min-width: 618px) {
    font-size: 16px;
  }
}

.ratingStars {
  background-color: transparent;
  margin: 0;
  width: auto;

  > div {
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }

  > span {
    font-style: normal;
    line-height: normal;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }

  > span:nth-child(3) {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
  }
}

.couponContainer {
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  > span:first-child {
    color: #828282;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;
  }

  > span:last-child {
    border-radius: 4px;
    border: 1px dashed #3b8456;
    background: rgba(59, 132, 86, 0.1);
    padding: 4px 12px;
    color: #3b8456;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.info {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  position: relative;
  height: 100%;
  border-top: 1px solid #d0e3db;
  border-bottom: 1px solid #d0e3db;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  pointer-events: auto;
  padding: 10px 0;

  @media (min-width: 618px) {
    border-left: 1px solid #d0e3db;
    border-right: 1px solid #d0e3db;
    border-top: none;
    min-width: 312px;
    border-bottom: none;
    padding: 0;
  }
}

.infoText {
  margin: 0;
}

.cta {
  z-index: 1;
  margin-bottom: 0;
  margin-top: 12px;
  max-width: 80%;
  @media (min-width: 618px) {
    margin-left: 8px;
    margin-top: 0;
    max-width: fit-content;
  }
}

.ctaButton {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  height: auto;
  background-color: #ffde00;

  padding: 12px 24px;
  border-radius: 100px;
  margin: 0;
  max-width: 100%;
  @media (min-width: 618px) {
    margin-right: 0px;
    max-width: 155px;
    width: fit-content;
  }
  > img {
    margin-left: 8px;
  }
}

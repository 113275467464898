@font-face {
  font-family: 'The Sun';
  src: url('https://thesun.co.uk/assets/fonts/the-sun/TheSun-Regular.woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'The Sun';
  src: url('https://thesun.co.uk/assets/fonts/the-sun/TheSun-Bold.woff2');
  font-weight: 700;
}

.container {
  font-family: 'The Sun';
}

@font-face {
  font-family: 'The Sun';
  src: url('https://thesun.co.uk/assets/fonts/the-sun/TheSun-Regular.woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'The Sun';
  src: url('https://thesun.co.uk/assets/fonts/the-sun/TheSun-Bold.woff2');
  font-weight: 700;
}

.container {
  flex-direction: row;
  border: 1px solid #bebfbf;
  border-radius: 0;
  font-family: 'The Sun';
  position: relative;

  @media (min-width: 618px) {
    gap: 0;
  }
}

.bgImage {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  object-fit: cover;
}

.topPart {
  flex-direction: row;
  width: 100%;
  gap: 14px;
  padding-bottom: 52px;
  position: relative;

  @media (min-width: 618px) {
    max-width: 100%;
    padding-bottom: 0;
    gap: 0;
  }
}

.image {
  margin: 20px;
  margin-right: 0;
  padding: 0;
  width: auto;
  background-color: transparent;

  @media (min-width: 618px) {
    margin: 17px 16px 18px 24px;
  }
}

.logo {
  height: 72px;
  width: 72px;
  border: 1px solid #000;
  border-radius: 0;
}

.info {
  width: fit-content;
  flex: unset;
  pointer-events: auto;
  border-left: 1px solid #dcdddd;
  line-height: 28px;
  font-size: 24px;
  border-left: none;
  padding-right: 0;
  text-align: left;
  padding-right: 20px;

  > p:first-child {
    margin-bottom: 5px;
  }

  @media (min-width: 618px) {
    flex: 1 0 0;
    border-left: none;
    padding: 10px 24px 10px 0;

    > p:first-child {
      margin-bottom: 0px;
    }
  }
}

.infoText {
  color: #fff;
  background-color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  display: inline;
  padding: 0 5px;

  @media (min-width: 618px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    max-width: 225px;
    display: block;
    padding: 3px;
  }
}

.cta {
  padding: 0px 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  position: relative;
  flex-direction: column;
  margin-top: -59px;

  @media (min-width: 618px) {
    position: absolute;
    right: 0;
    width: fit-content;
  }
}

.ctaButton {
  background: #0072ee;
  height: 43px;
  max-height: 43px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: capitalize;
  padding: 0;
  color: white;

  @media (min-width: 618px) {
    margin-top: 0;
    width: 155px;
    height: 52px;
    min-height: 52px;
    font-size: 20px;
    margin-top: 13px;
  }
}

.tandc {
  background: #eb1701;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 47px;

  &:before {
    background-image: url('../../assets/images/BeGambleAware-thesun.svg');
    content: ' ';
    min-width: 103px;
    height: 21px;
    left: calc(50% - 92px);
    background-repeat: no-repeat;
    top: 16px;
    background-size: cover;
    margin-right: 8.5px;

    @media (min-width: 618px) {
      left: 24px;
    }
  }
}

.tandcUS{
   &:before{
    background-image: url('../../assets/images/21-BeGambleAware.svg');
   }
}

.tandcText {
  padding-right: 8px;
  height: 39px;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  color: #fff;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.106);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #9191917c;
  }
}

.tandc.sportsbook {
  background: #427f8f;
}

.tandc.sweepstake {
  background: #eb8d01;
}

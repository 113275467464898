.tab {
  padding: 0 1rem;
  display: inline-block;
  list-style: none;
  border-bottom: 0.3rem solid transparent;
  cursor: pointer;
  &:hover {
    background-color: #12a2ef12;
  }
}

.active {
  border-bottom: 0.3rem solid #12a2ef;
}

h2 {
  display: flex;
  line-height: 29px;
  font-size: 24px;
  font-weight: 700;
  align-items: center;
  font-style: normal;
  color: #000;
}

.list {
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
}

.loadMore {
  display: flex;
}

.description {
  > p:first-child {
    color: #6f6f6f;
    font-size: 12px;
    line-height: 30px;
  }

  > p:nth-child(2) {
    color: #2f2f2f;
    font-size: 14px;
    line-height: 21px;
    border-radius: 3px;
    background: #eaeffc;
    padding: 8px;
  }
}

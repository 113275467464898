@font-face {
  font-family: 'Barlow';
  font-weight: 400;
  src: url('../../assets/fonts/Barlow-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 700;
  src: url('../../assets/fonts/Barlow-Bold.woff2') format('woff2');
}

.container {
  background-color: #262626;
  border-radius: 6px;
  border-left: 16px #0b77a5 solid;
  flex-direction: row;
  height: 296px;
  align-items: flex-start;
  position: relative;
  text-decoration: none;

  @media (min-width: 618px) {
    height: 250px;
    flex-direction: column;
  }

  &:after {
    content: '';
    width: 62%;
    height: 100%;
    background-image: url('../../assets/images/Rectangle.png');
    right: 0;
    position: absolute;
    z-index: 0;

    @media (min-width: 618px) {
      width: 192px;
      right: 40px;
    }
  }
}

.image {
  margin: 0;
  background-color: transparent;
  width: 112px;
  height: 112px;
  flex: none;
  padding: 0;
  z-index: 2;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  margin: 0 auto;

  @media (min-width: 618px) {
    margin-left: -16px;
  }

  img {
    border-radius: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.info {
  position: relative;
  z-index: 1;
  width: 100%;
  flex: unsetu;

  p {
    background: #f9dc1c;
    font-style: normal;
    color: #106985;
    line-height: 40px;
    text-transform: uppercase;
    font-family: 'Barlow';
    font-size: 24px;
    line-height: 32px;
    width: 93%;

    @media (min-width: 618px) {
      font-size: 36px;
      font-weight: 700;
      width: 100%;
    }
  }

  &:after {
    content: ' ';
    width: 100%;
    height: 140px;
    position: absolute;
    background-color: #e53525;
    top: -30px;
    left: 0;
    z-index: -1;

    @media (min-width: 618px) {
      top: -13px;
      left: 20px;
      width: 85%;
      height: 140px;
    }
  }
}

.tandc {
  display: none;
}

.topPart {
  gap: 0.8rem;
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
  margin-top: 16px;

  @media (min-width: 618px) {
    width: 80%;
    margin-top: 28px;
  }
}

.cta {
  width: auto;
  margin: 0 auto;
  z-index: 1;
}

.ctaButton {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 19px;
  background: #fff;
  border-radius: 0;
  font-family: Barlow;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  text-transform: none;
  border-radius: 8px;
  border-bottom: 4px solid #ccc;
  padding: 11px 24px 11px 12px;
  margin: -16px;

  @media (min-width: 600px) {
    gap: 33px;
    padding: 11px 43px 11px 12px;
  }
}

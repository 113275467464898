.item {
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.image {
  position: relative;
  &::before {
    content: attr(data-rank);
    border-radius: 4px 0px;
    background: #f8fafc;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -7px;
    left: -7px;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.topPart {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 618px) {
    flex-direction: row;
  }
}

.logo {
  display: flex;
  gap: 16px;
  flex-grow: 1;
  > a {
    > img {
      width: 88px;
      height: 88px;
      border-radius: 8px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;
    span {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      color: #0f172a;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #f59e0b;
      background: #fffbeb;
      width: fit-content;
      min-width: 60px;
      padding: 3px 16px;
  }

      @media (min-width: 618px) {
      }

      > img {
        margin-right: 4px;
      }

      > span {
        color: #451a03;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
      }
      > span:last-child {
        font-size: 14px;
      }
    }
  }


.sellingPoint {
  padding: 0;
  width: 100%;
  list-style: none;

  @media (min-width: 618px) {
    width: 50%;
  }

  li {
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #0f172a;
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.couponCode {
  border-radius: 4px;
  border: 1px dashed #64748b;
  background: #f8fafc;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  padding: 8px 16px;
  padding-right: 4.5rem;
  text-align: center;
  position: relative;
  color: #0f172a;



  button {
    border: none;
    cursor: pointer;
    gap: 4px;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    background: #f1f5f9;
    padding: 4px 8px;
    color: #0f172a;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bottomPart {
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media (min-width: 618px) {
    flex-direction: row;
  }

  > div {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.2);
    width: auto;
    text-align: center;

    @media (min-width: 618px) {
      width: 50%;
    }

    p {
      font-size: 20px;
      color: #0f172a;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }

    p:last-of-type {
      font-size: 16px;
    }
  }
}

.ctaButton {
  border-radius: 4px;
  background: #22c55e;
  box-shadow: 0px 2px 8px 0px rgba(84, 149, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eff6ff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 50%;
  text-decoration: none;
  gap: 8px;
  padding: 16px 24px;
  width: auto;

  @media (min-width: 618px) {
    width: 50%;
  }
}

@font-face {
  font-family: 'Cuprum';
  font-weight: 400;
  src: url('../../assets/fonts/Cuprum\ Regular.woff2') format('woff2');
}

.container {
  font-family: 'Cuprum';
  max-width: 728px;
}

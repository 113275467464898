@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Montserrat-Bold.woff2') format('woff2');
}

.container {
  flex-direction: row;
  border: 1px solid #000;
  border-radius: 0;
  justify-content: space-between;
  font-family: 'Montserrat';
  position: relative;
}

.bgImage {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  object-fit: cover;
  display: none;

  @media (min-width: 618px) {
    display: block;
  }
}

.bgImageMob {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  object-fit: cover;

  @media (min-width: 618px) {
    display: none;
  }
}

.topPart {
  width: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-basis: 70%;
  
}

.image {
  margin: 16px;
  margin-bottom: 0;
  padding: 0;
  width: auto;
  background-color: transparent;

  @media (min-width: 618px) {
    margin-bottom: 16px;
  }
}

.logo {
  height: 72px;
  width: 72px;
  border: 1px solid #000;
  margin-bottom: 16px;
  border-radius: 0;
  @media (min-width: 618px) {
    margin-bottom: 0;
  }
}

.info {
  width: fit-content;
  right: 0;
  flex: unset;
  pointer-events: auto;
  text-align: left;

  @media (min-width: 618px) {
    margin-right: 0;
    margin-left: 0;
    position: unset;
    flex: 1 0;
    align-self: center;
  }
}

.infoText {
  width: fit-content;
  text-align: start;
  color: #fff;
  background-color: #000;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.cta {
  padding: 0 16px;
  width: 100%;
  margin: 0 0px 22px 0;
  @media (min-width: 618px) {
    max-width: 148px;
    margin: 0;
    padding: 0 16px;
  }
}

.ctaButton {
  border-radius: 0;
  line-height: 25px;
  font-size: 18px;
  font-weight: 800;
  box-shadow: 4px 4px 0 #000;
  color: #000;
  background-color: #fff200;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  @media (min-width: 618px) {
    max-width: 148px;
    box-shadow: none;
  }
}

.tandc {
  background-color: rgba(0, 0, 0, 0.5);
  max-height: 48px;
}

.tandcText {
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: #fff;
}
